import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';

const Stack = () => {
    return (
        <Layout>
            <Link className='text-gray-400 hover:text-gray-300' to='/'>← Back</Link>
			<h1 className='text-3xl mt-8'>Readings</h1>
            <p className='my-4'>An incomplete list of books and articles I read and enjoyed.</p>
            <div>
                <h3 className='text-xl font-semibold mt-8 mb-4'>2021</h3>
                <ul className='list-disc list-inside'>
                    <li className='my-2'>Ryan Holiday: The Obstacle Is the Way</li>
                    <li className='my-2'>Michael Hampe: Die Wildnis, die Seele, das Nichts</li>
                    <li className='my-2'>Anna Schriefl: Stoische Philosophie</li>
                </ul>
                <h3 className='text-xl font-semibold mt-8 mb-4'>2020</h3>
                <ul className='list-disc list-inside'>
                    <li className='my-2'>Marty Cagan: Inspired: How to Create Tech Products Customers Love</li>
                    <li className='my-2'>Eric A. Posner &amp; Glen Weyl: Radical Markets</li>
                    <li className='my-2'>John Ousterhout: A Philosophy of Software Design</li>
                </ul>
                <h3 className='text-xl font-semibold mt-8 mb-4'>2019</h3>
                <ul className='list-disc list-inside'>
                    <li className='my-2'>Greg McKeown: Essentialism</li>
                    <li className='my-2'>James Clear: Atomic Habits</li>
                    <li className='my-2'>Cal Newport: Deep Work</li>
                    <li className='my-2'>Peter Thiel: Zero to One</li>
                    <li className='my-2'>Simon Nora: Computerization of Society</li>
                    <li className='my-2'>Émile Durkheim: The Division of Labor in Society</li>
                </ul>
            </div>
        </Layout>
    )
}

export default Stack;